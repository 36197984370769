const ConditionalRender = ({ condition, children }) => {
    if(condition) {
        return children;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};


export default  ConditionalRender;
