import React, {useEffect, useRef, useState} from "react";
import {Button, Stack, TextField, Typography} from "@mui/material";
import CameraIcon from "@mui/icons-material/Videocam";
import CameraOffIcon from "@mui/icons-material/VideocamOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import ConditionalRender from "./common/ConditionalRender";
import ModalWindow from "./common/ModalWindow";
import Media from "./common/Media";
const StartingRoom = ({ stream, tracks, username, onJoin, options, setOptions }) => {
  const ref = useRef();
  const [name, setName] = useState(username);
  const [openCamera, setOpenCamera] = useState(false)
  useEffect(() => {
      if(ref.current){
          ref.current.srcObject = stream;
      }
  }, [stream]);

  const toggleMic = () => {
    setOptions((prevState) => ({...prevState, micOn: !prevState.micOn}));
    const [audioTrack] = stream.getAudioTracks();
    audioTrack.enabled = !(audioTrack.enabled);
  }

  const toggleCamera = async () => {
      try {
          const [videoTrack] = stream.getVideoTracks();
          videoTrack.enabled = !(videoTrack.enabled);
          setOptions((prevState) => ({...prevState, cameraOn: !prevState.cameraOn}));
      } catch (e) {
          setOpenCamera(true)
          setOptions((prevState) => ({...prevState, cameraOn: false}));
      }
  }

  return (
    <>
      <div className={"join-header"}>
        <h2>Get yourself prepared.</h2>
      </div>
      <div className={'join-container'}>
          <div className={`media-container`}>
            <Media ref={ref} tracks={tracks} options={options} />
          </div>
        <div className={'join-actions'}>
          <button onClick={toggleMic}>
            {options.micOn ? <MicIcon /> : <MicOffIcon />}
          </button>
          <button onClick={toggleCamera}>
            {options.cameraOn ? <CameraIcon/> : <CameraOffIcon/>}
          </button>
        </div>
      </div>
      <ConditionalRender condition={username === 'Guest'}>
        <TextField
            sx={{mt: 2}}
            fullWidth
            label={'Name Surname (optional)'}
            placeholder={'Guest'}
            variant="standard"
            value={name}
            onChange={e => setName(e.target.value)}
        />
      </ConditionalRender>
      <div className={'control-wrapper'}>
        <button
            className={'join-btn'}
            onClick={() => onJoin(name)}
        >
          Join call
        </button>
      </div>
      <ModalWindow
          open={openCamera}
          title={'Камера заблокирована'}
      >
          <Typography>
              The Meet app needs access to the camera. Click on the locked camera icon
              <img
                  style={{margin: '0 5px'}}
                  src="https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg"
                  alt="camera"
              />
              in the address bar of the browser.
          </Typography>
          <Stack alignItems={'flex-end'}>
              <Button
                  variant={'outlined'}
                  onClick={() => setOpenCamera(false)}>
                  Skip
              </Button>
          </Stack>
      </ModalWindow>
    </>
  )
}

export default StartingRoom;
