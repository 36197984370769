export const downloadRecording = async (blob) => {
    const a = document.createElement('a');
    const videoBlob = await fetch(blob).then((r) => r.blob());
    const url = window.URL.createObjectURL(videoBlob);

    document.body.appendChild(a);

    a.href = url;
    a.download = `Recording ${new Date().getTime()}`;
    a.click();

    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }, 0)
}
