import ConditionalRender from "./ConditionalRender";
import {StyledCameraWrapper} from "./Styled";
import CameraOffIcon from "@mui/icons-material/VideocamOff";
import React, {forwardRef} from "react";
import MicOffIcon from "@mui/icons-material/MicOff";

const Media = forwardRef(({tracks, options, name, mineMicro}, ref) => {

    return (
        <>
            <ConditionalRender condition={tracks.find(track => track.kind === "audio") && tracks.length === 1}>
                <audio
                    className={`hidden`}
                    muted
                    autoPlay
                    ref={ref}
                />
            </ConditionalRender>
            <ConditionalRender condition={tracks.find(item => item.kind === 'video')}>
                <video
                    className={`${options?.cameraOn ? '' : 'hidden'}`}
                    playsInline
                    muted
                    autoPlay
                    ref={ref}
                />
            </ConditionalRender>
            <ConditionalRender condition={name}>
                <div className="username">
                    <span>
                        {name}
                    </span>
                    <ConditionalRender condition={!options?.micOn}>
                        <MicOffIcon />
                    </ConditionalRender>
                </div>

            </ConditionalRender>
            <ConditionalRender condition={!options?.cameraOn || !tracks.find(item => item.kind === 'video')}>
                <StyledCameraWrapper>
                    <CameraOffIcon fontSize={'large'}/>
                </StyledCameraWrapper>
            </ConditionalRender>
        </>
    )
})
export default Media
