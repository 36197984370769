import {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import ConditionalRender from "../common/ConditionalRender";

const Timer = ({startCall}) => {
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const changeTime = () => {
        if(seconds === 59) {
            setSeconds(0);
            setMinutes((minutes) => {
                if(minutes === 59) {
                    setHours((hours) => (hours + 1))
                    return 0
                }
                return (minutes + 1)
            });
        } else {
            setSeconds((second) => (second + 1));
        }
    };

    const getDefaultTimer = (date) => {
        const now = new Date().getTime()
        const newSeconds = Math.trunc(Math.abs(now - date.getTime()) / 1000)
        const newMinutes = Math.trunc(newSeconds / 60)

        if(newSeconds <= 60) {
            setSeconds(newSeconds)
        } else {
            setSeconds(newSeconds % 60)
            if (newMinutes <= 60) {
                setMinutes(newMinutes)
            } else {
                setMinutes(Math.trunc(newMinutes % 60))
                setHours(Math.trunc(newMinutes / 60))
            }
        }
        // changeDefaultTime(Math.round(seconds))
    }

    useEffect(() => {
        if(startCall) {
            const date = new Date(startCall)
            getDefaultTimer(date)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startCall])

    useEffect(() => {
        if(startCall) {
            const timer = setInterval(() => changeTime(seconds), 1000);
            return (() =>{
                clearInterval(timer);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds, minutes, hours]);

    return (
            <Typography
                position={'relative'}
                left={'10px'}
                variant={'h5'}
            >
                <ConditionalRender condition={hours !== 0}>
                    {String(hours)}:
                </ConditionalRender>
                {String(minutes).padStart(2, '0')}
                :
                {String(seconds).padStart(2, '0')}
            </Typography>
    )
}
export default Timer
