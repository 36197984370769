import React, {useEffect, useRef, useState} from "react";
import hark from "hark";
import ConditionalRender from "../common/ConditionalRender";
import MicOffIcon from "@mui/icons-material/MicOff";
import {StyledCameraWrapper} from "../common/Styled";
import CameraOffIcon from "@mui/icons-material/VideocamOff";

const Video = ({peer, username, media}) => {
  const ref = useRef();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
      peer.on("stream", stream => {
        ref.current.srcObject = stream;
        const speech = hark(stream);

        speech.on('speaking', () => {
          setIsSpeaking(true);
        });

        speech.on('stopped_speaking', () => {
          setIsSpeaking(false);
        });

        setTracks(stream.getTracks());
      })
  }, [peer]);

  return (
      <div className={`media-container ${isSpeaking ? 'speaking' : ''}`}>
          <ConditionalRender condition={tracks.find(track => track.kind !== "video") && tracks.length === 1}>
              <audio
                  className={`hidden`}
                  muted={!media?.micOn}
                  autoPlay
                  ref={ref}
              />
          </ConditionalRender>
          <video
              className={`${media?.cameraOn ? '' : 'hidden'}`}
              playsInline
              muted={!media?.micOn}
              autoPlay
              ref={ref}
          />
          <div className="username">
              <span>
                  {username}
              </span>
              <ConditionalRender condition={!media?.micOn}>
                  <MicOffIcon />
              </ConditionalRender>
          </div>
          <ConditionalRender condition={!media?.cameraOn || !tracks.find(item => item.kind === 'video')}>
              <StyledCameraWrapper>
                  <CameraOffIcon fontSize={'large'}/>
              </StyledCameraWrapper>
          </ConditionalRender>

      </div>
  );
}

export default Video
