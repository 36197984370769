import {Box, styled, Typography} from "@mui/material";

export const StyledModalBox = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    background: 'rgb(255,255,255)',
    boxShadow: 24,
    padding: '25px',
    borderRadius: '25px'
}))
export const StyledModalTitle = styled(Typography)(() => ({

}))

export const StyledCameraWrapper = styled('div')(() => ({
    background: '#e2e2e242',
    padding: '60px',
    borderRadius: '60px',
    '& svg': {
        color: 'white'
    }
}))
