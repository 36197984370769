import {Modal} from "@mui/material";
import {StyledModalBox, StyledModalTitle} from "./Styled";

const ModalWindow = ({open, handleClose, children, title}) => {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <StyledModalBox>
                <StyledModalTitle
                    variant={'h5'}
                    mb={'10px'}
                    fontWeight={'700'}
                >
                    {title}
                </StyledModalTitle>
                {children}
            </StyledModalBox>
        </Modal>
    )
}
export default ModalWindow
