import './App.css';
import {useEffect, useState} from "react";
import Room from "./components/Room";
import StartingRoom from "./components/StartingRoom";
import {useLocation} from "react-router-dom";
import ConditionalRender from "./components/common/ConditionalRender";

const App = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [roomId] = location.pathname.slice(1).split("?");
  const [isInRoom, setIsInRoom] = useState(false);
  const [myMedia, setMyMedia] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [username, setUsername] = useState(query.get("username") || "Guest");
  const [options, setOptions] = useState({micOn: true, cameraOn: true});

  const setInitStream = (stream) => {
    setMyMedia(stream);
    setTracks(stream.getTracks())
  }
  const initStream = async () => {
    let stream = null;

    try {
      //get audio and video
      stream = await getMedia();
      await setInitStream(stream)
      setOptions({ micOn: true, cameraOn: true })
    } catch (e) {
      //get only audio
      stream = await getMedia(false, true);
      await setInitStream(stream)
      setOptions({ micOn: true, cameraOn: false })
      try {
        //get only video
        stream = await getMedia(true, false);
        await setInitStream(stream)
        setOptions({ micOn: false, cameraOn: true })
      } catch (error) {
        //No mic and camera
        stream = null;
        setOptions({ micOn: false, cameraOn: false })
      }
    }

    return stream;
  }

  const getMedia = (video = true, audio = true) => {
    try {
      return navigator.mediaDevices.getUserMedia({
        video: video,
        audio: audio
      });
    } catch (e) {
      console.log(e)
    }
  }

  const joinRoom = (name) => {
    setUsername(name)
    setIsInRoom(true);
  }

  useEffect(() => {
    initStream()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ConditionalRender condition={isInRoom}>
        <Room
          stream={myMedia}
          tracks={tracks}
          roomId={roomId}
          username={username}
          options={options}
        />
      </ConditionalRender>
      <ConditionalRender condition={!isInRoom}>
        <StartingRoom
          onJoin={joinRoom}
          options={options}
          stream={myMedia}
          tracks={tracks}
          username={username}
          setOptions={setOptions}
        />
      </ConditionalRender>
    </>
  )
}

export default App;
