import React, {useEffect, useRef, useState} from "react";
import hark from "hark";
import io from "socket.io-client";
import Controls from "./Controls";
import Video from "./video/Item";
import Media from "./common/Media";
import {Snackbar} from "@mui/material";

// DO NOT DIVIDE MY MEDIA AND OTHER VIDEOS INTO ONE COMPONENT
// IT LEADS TO BLACK SCREEN

// const socket = io.connect('http://localhost:4000');
const socket = io.connect(process.env.REACT_APP_API_URL);

const Room = ({stream, tracks, roomId, username, options}) => {
  const [fullRoom, setFullRoom] = useState(false)
  const [declineUser, setDeclineUser] = useState('')
  const [declineSnack, setDeclineSnack] = useState(false)
  const [startCallTime, setStartCallTime] = useState(new Date())
  const [peers, setPeers] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const userVideo = useRef();
  const peersRef = useRef([]);
  const [micOn, setMicOn] = useState(options.micOn);
  const [cameraOn, setCameraOn] = useState(options.cameraOn);

  useEffect(() => {
    if (stream) {
      setUpMyMedia(stream);
      socket.emit("join-room", {
        roomId,
        username,
        now: new Date(),
        media: options
      });

      socket.on("all-users", ({time, users}) => {
        const peers = [];
        if(users.length) {
          setStartCallTime(time)
        }

        users.forEach((user) => {
          const peer = createPeer(user.id, socket.id, stream);

          peersRef.current.push({
            peerId: user.id,
            peer,
          })

          peers.push({
            peer,
            peerId: user.id,
            name: user.username,
          });
        })
        setPeers(peers);
      })

      socket.on('decline-call', ({username}) => {
        setDeclineUser(username)
        setDeclineSnack(true)
      })

      socket.on("user-disconnected", (callerId) => {
        peersRef.current.splice(peersRef.current.findIndex(peer => peer.peerId === callerId));
        // setPeers(peersRef.current);
        setPeers(peers => peers.filter(({peerId}) => peerId !== callerId));
      })

      socket.on("receive-signal", ({ signal, id }) => {
        const item = peersRef.current.find(peer => peer.peerId === id);

        item.peer.signal(signal);
      });

      socket.on('room-full', () => {
        setFullRoom(true)
        setTimeout(() => {
            window.location.reload();
        }, 2000)
      })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]);


  useEffect(() => {
    socket.on("user-connected", ({signal, callerId, username, options}) => {
      const peer = addPeer(signal, callerId, stream);

      peersRef.current.push({
        peerId: callerId,
        peer,
      })

      setPeers(users => [...users, {
        peer,
        peerId: callerId,
        name: username,
        media: options
      }]);
    });
    socket.emit('media-toggle', {
      media: {
        micOn: micOn,
        cameraOn: cameraOn
      }
    })
    socket.on('media-toggle', ({userId, media}) => {
      setPeers((peers) => peers.map(item => {
        if(item.peerId === userId){
          item.media = media
        }
        return item
      }))
    })
    return () => {
      socket.removeAllListeners("media-toggle");
      socket.removeAllListeners("user-connected");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peers.length])

  const setUpMyMedia = (stream) => {
    userVideo.current.srcObject = stream;

    const speech = hark(stream);

    speech.on('speaking', () => {
      setIsSpeaking(true);
    });

    speech.on('stopped_speaking', () => {
      setIsSpeaking(false);
    });
  }

  const createPeer = (userToSignal, callerId, stream) => {
    const peer = new window.SimplePeer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", signal => {
      socket.emit("send-signal", {userToSignal, callerId, signal, username, options})
    })

    return peer;
  }

  const addPeer = (incomingSignal, callerId, stream) => {
    const peer = new window.SimplePeer({
      initiator: false,
      trickle: false,
      stream,
    })

    peer.on("signal", signal => {
      socket.emit("return-signal", {signal, callerId, roomId, options})
    })

    peer.signal(incomingSignal);

    return peer;
  }

  return (
    <div>
      <div className={`call-container grid-${peers.length + 1}`}>
        <div className={`media-container ${isSpeaking ? 'speaking' : ''}`}>
          <Media
              tracks={tracks}
              ref={userVideo}
              options={{micOn, cameraOn}}
              name={username}
          />
        </div>
        {peers.map(({peer, name, peerId, media}) =>
          <Video
              socket={socket}
              key={peerId}
              peer={peer}
              username={name}
              media={media}
          />
        )}
      </div>
      <Controls
          socket={socket}
          micOn={micOn}
          cameraOn={cameraOn}
          setMicOn={setMicOn}
          setCameraOn={setCameraOn}
          startCall={startCallTime}
          stream={stream}
      />
      <Snackbar
          open={declineSnack}
          autoHideDuration={3000}
          message={`${declineUser} declined the call.`}
          onClose={() => {
            setDeclineUser('')
            setDeclineSnack(false)
          }}
      />
      <Snackbar
          open={fullRoom}
          autoHideDuration={3000}
          message={'Sorry, but the room is full!'}
          onClose={() => setFullRoom(false)}
      />
    </div>
  );
};

export default Room;

